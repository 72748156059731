import React from 'react';

const Policies = () => {
  return (
    <div style={{ padding: '20px' }}>
      <h2>Terms and Conditions</h2>
      <p>
        By accessing or using this website, you agree to abide by the terms and conditions outlined below. Failure to comply with these terms may result in legal actions. The content, products, and services on this website are provided “as is” without warranties of any kind. Users are responsible for maintaining the confidentiality of their account details.
      </p>

      <h2>Privacy Policy</h2>
      <p>
        We are committed to protecting your privacy. We collect personal information such as name, email, and contact details for the purpose of providing services. This data is stored securely and will not be shared with third parties except as required by law. Cookies are used to improve user experience.
      </p>

      <h2>Refund and Cancellation Policy</h2>
      <p>
        Customers are eligible for refunds under the following conditions:
      </p>
      <ul>
        <li>Requests for refunds must be made within <strong>14 days</strong> of purchase.</li>
        <li>Refunds will be processed within <strong>5-7 working days</strong> after approval, and the credited amount will be returned to the customer’s original method of payment.</li>
        <li>Cancellations can be made within <strong>24 hours</strong> of placing the order. After this period, cancellations may not be accepted depending on the product or service.</li>
      </ul>

      <h2>Pricing</h2>
      <p>
        All prices listed on the website are in Indian Rupees (INR) and include applicable taxes. Prices are subject to change without prior notice.
      </p>

      <h2>Shipping Policy</h2>
      <p>
        Orders will be processed within <strong>2 business days</strong> of payment confirmation. Shipping timelines vary depending on the location:
      </p>
      <ul>
        <li><strong>Metro cities</strong>: 3-5 business days</li>
        <li><strong>Other regions</strong>: 5-7 business days</li>
      </ul>
      <p>
        We also offer express shipping options at an additional cost.
      </p>

      <h2>Contact Us</h2>
      <p>
        For any queries, feel free to reach out to us:
      </p>
      <ul>
        <li><strong>Email</strong>: aabagarments522@gmail.com</li>
        <li><strong>Phone</strong>: +91-9744 557277</li>
        <li><strong>Address</strong>: AABA GARMENTS, Ernakulam, KE, 682006 </li>
      </ul>

      <h2>Products and Services</h2>
      <p>
        We offer a wide range of products, including [Product A, Product B, etc.]. Detailed descriptions, pricing, and terms specific to each product are available on the product pages. Services include [Service A, Service B], and all services are governed by these policies.
      </p>
    </div>
  );
};

export default Policies;
