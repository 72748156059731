export const navigation = {
    categories: [
      {
        id: 'women',
        name: "Women's",
        featured: [
          {
            name: 'New Arrivals',
            href: '/',
            imageSrc: 'https://tailwindui.com/img/ecommerce-images/mega-menu-category-01.jpg',
            imageAlt: 'Models sitting back to back, wearing Basic Tee in black and bone.',
          },
          {
            name: 'Basic Tees',
            href: '/',
            imageSrc: 'https://tailwindui.com/img/ecommerce-images/mega-menu-category-02.jpg',
            imageAlt: 'Close up of Basic Tee fall bundle with off-white, ochre, olive, and black tees.',
          },
        ],
        sections: [
          {
            id: 'clothing',
            name: 'Clothing',
            items: [
              // { name: 'Tops', id:"top", href: `{women/clothing/tops}` },
              // { name: 'Dresses', id:"women_dress", href: '#' },
              // { name: 'Women Jeans', id: 'women_jeans' },
              // { name: 'Lengha Choli', id: 'lengha_choli' },
              // { name: 'Sweaters', id: 'sweater' },
              // { name: 'T-Shirts', id: 't-shirt' },
              // { name: 'Jackets', id: 'jacket' },
              // { name: 'Gouns', id: 'gouns' },
              // { name: 'Sarees', id: 'saree' },
              // { name: 'Kurtas', id: 'kurtas' },
              // { name: 'Kurtas', id: 'kurtas' },
              // { name: 'Shirt', id: 'shirt' },
              { name: 'Kurtie', id:"womens_kurtie" },
              { name: 'Top Set', id:"womens_top_set" },
              { name: 'Saree', id: 'womens_saree' },
              { name: 'Nightie', id:"womens_nightie" },
            ],
          },
          // {
          //   id: 'accessories',
          //   name: 'Accessories',
          //   items: [
          //     { name: 'Watches', id: 'watch' },
          //     { name: 'Wallets', id: 'wallet' },
          //     { name: 'Bags', id: 'bag' },
          //     { name: 'Sunglasses', id: 'sunglasse' },
          //     { name: 'Hats', id: 'hat' },
          //     { name: 'Belts', id: 'belt' },
          //   ],
          // },
          // {
          //   id: 'brands',
          //   name: 'Brands',
          //   items: [
          //     { name: 'Full Nelson', id: '#' },
          //     { name: 'My Way', id: '#' },
          //     { name: 'Re-Arranged', id: '#' },
          //     { name: 'Counterfeit', id: '#' },
          //     { name: 'Significant Other', id: '#' },
          //   ],
          // },
        ],
      },
      {
        id: 'men',
        name: "Men's",
        featured: [
          {
            name: 'New Arrivals',
            id: '#',
            imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-04-detail-product-shot-01.jpg',
            imageAlt: 'Drawstring top with elastic loop closure and textured interior padding.',
          },
          {
            name: 'Artwork Tees',
            id: '#',
            imageSrc: 'https://tailwindui.com/img/ecommerce-images/category-page-02-image-card-06.jpg',
            imageAlt:
              'Three shirts in gray, white, and blue arranged on table with same line drawing of hands and shapes overlapping on front of shirt.',
          },
        ],
        sections: [
          {
            id: 'clothing',
            name: 'Clothing',
            items: [
              // { name: 'Mens Kurtas', id: 'mens_kurta' },
              // { name: 'Shirt', id: 'shirt' },
              // { name: 'Men Jeans', id: 'men_jeans' },
              // { name: 'Sweaters', id: '#' },
              // { name: 'T-Shirts', id: 't-shirt' },
              // { name: 'Jackets', id: '#' },
              // { name: 'Activewear', id: '#' },

              { name: 'Dhotie', id: 'mens_dhotie' },
              { name: 'Lungie', id: 'mens_kurta' },
              { name: 'Brief', id: 'mens_brief' },
              { name: 'Vest', id: 'mens_vest' },
              
            ],
          },
          // {
          //   id: 'accessories',
          //   name: 'Accessories',
          //   items: [
          //     { name: 'Watches', id: '#' },
          //     { name: 'Wallets', id: '#' },
          //     { name: 'Bags', id: '#' },
          //     { name: 'Sunglasses', id: '#' },
          //     { name: 'Hats', id: '#' },
          //     { name: 'Belts', id: '#' },
          //   ],
          // },
          // {
          //   id: 'brands',
          //   name: 'Brands',
          //   items: [
          //     { name: 'Re-Arranged', id: '#' },
          //     { name: 'Counterfeit', id: '#' },
          //     { name: 'Full Nelson', id: '#' },
          //     { name: 'My Way', id: '#' },
          //   ],
          // },
        ],
      },
      {
        id: 'kid',
        name: "Kid's",
        featured: [
          {
            name: 'New Arrivals',
            id: '#',
            imageSrc: 'https://cdn.fcglcdn.com/brainbees/images/products/583x720/17150244a.webp',
            imageAlt: 'Drawstring top with elastic loop closure and textured interior padding.',
          },
          {
            name: 'Artwork Tees',
            id: '#',
            imageSrc: 'https://cdn.fcglcdn.com/brainbees/images/products/583x720/18117456a.webp',
            imageAlt:
              'Three shirts in gray, white, and blue arranged on table with same line drawing of hands and shapes overlapping on front of shirt.',
          },
        ],
        sections: [
          {
            id: 'clothing',
            name: 'Clothing',
            items: [
              { name: 'Shirts', id: 'kids_shirts' },
              { name: 'T-Shirt', id: 'kids_t-shirt' },
              { name: 'Pants', id: 'kids_pants' },
              { name: 'Shorts', id: 'kids_shorts' },
              
            ],
          },
          // {
          //   id: 'accessories',
          //   name: 'Accessories',
          //   items: [
          //     { name: 'Watches', id: '#' },
          //     { name: 'Wallets', id: '#' },
          //     { name: 'Bags', id: '#' },
          //     { name: 'Sunglasses', id: '#' },
          //     { name: 'Hats', id: '#' },
          //     { name: 'Belts', id: '#' },
          //   ],
          // },
          // {
          //   id: 'brands',
          //   name: 'Brands',
          //   items: [
          //     { name: 'Re-Arranged', id: '#' },
          //     { name: 'Counterfeit', id: '#' },
          //     { name: 'Full Nelson', id: '#' },
          //     { name: 'My Way', id: '#' },
          //   ],
          // },
        ],
      },
    ],
    pages: [
      // { name: 'Company', id: '/' },
      // { name: 'Stores', id: '/' },
    ],
  }






















  // export const navigation = {
  //   categories: [
  //     {
  //       id: 'women',
  //       name: 'Women',
  //       featured: [
  //         {
  //           name: 'New Arrivals',
  //           href: '/',
  //           imageSrc: 'https://tailwindui.com/img/ecommerce-images/mega-menu-category-01.jpg',
  //           imageAlt: 'Models sitting back to back, wearing Basic Tee in black and bone.',
  //         },
  //         {
  //           name: 'Basic Tees',
  //           href: '/',
  //           imageSrc: 'https://tailwindui.com/img/ecommerce-images/mega-menu-category-02.jpg',
  //           imageAlt: 'Close up of Basic Tee fall bundle with off-white, ochre, olive, and black tees.',
  //         },
  //       ],
  //       sections: [
  //         {
  //           id: 'clothing',
  //           name: 'Clothing',
  //           items: [
  //             { name: 'Tops', id:"top", href: `{women/clothing/tops}` },
  //             { name: 'Dresses', id:"women_dress", href: '#' },
  //             { name: 'Women Jeans', id: 'women_jeans' },
  //             { name: 'Lengha Choli', id: 'lengha_choli' },
  //             { name: 'Sweaters', id: 'sweater' },
  //             { name: 'T-Shirts', id: 't-shirt' },
  //             { name: 'Jackets', id: 'jacket' },
  //             { name: 'Gouns', id: 'gouns' },
  //             { name: 'Sarees', id: 'saree' },
  //             { name: 'Kurtas', id: 'kurtas' },
  //             { name: 'Kurtas', id: 'kurtas' },
  //             { name: 'Shirt', id: 'shirt' },
  //           ],
  //         },
  //         // {
  //         //   id: 'accessories',
  //         //   name: 'Accessories',
  //         //   items: [
  //         //     { name: 'Watches', id: 'watch' },
  //         //     { name: 'Wallets', id: 'wallet' },
  //         //     { name: 'Bags', id: 'bag' },
  //         //     { name: 'Sunglasses', id: 'sunglasse' },
  //         //     { name: 'Hats', id: 'hat' },
  //         //     { name: 'Belts', id: 'belt' },
  //         //   ],
  //         // },
  //         // {
  //         //   id: 'brands',
  //         //   name: 'Brands',
  //         //   items: [
  //         //     { name: 'Full Nelson', id: '#' },
  //         //     { name: 'My Way', id: '#' },
  //         //     { name: 'Re-Arranged', id: '#' },
  //         //     { name: 'Counterfeit', id: '#' },
  //         //     { name: 'Significant Other', id: '#' },
  //         //   ],
  //         // },
  //       ],
  //     },
  //     // {
  //     //   id: 'men',
  //     //   name: 'Men',
  //     //   featured: [
  //     //     {
  //     //       name: 'New Arrivals',
  //     //       id: '#',
  //     //       imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-04-detail-product-shot-01.jpg',
  //     //       imageAlt: 'Drawstring top with elastic loop closure and textured interior padding.',
  //     //     },
  //     //     {
  //     //       name: 'Artwork Tees',
  //     //       id: '#',
  //     //       imageSrc: 'https://tailwindui.com/img/ecommerce-images/category-page-02-image-card-06.jpg',
  //     //       imageAlt:
  //     //         'Three shirts in gray, white, and blue arranged on table with same line drawing of hands and shapes overlapping on front of shirt.',
  //     //     },
  //     //   ],
  //     //   sections: [
  //     //     {
  //     //       id: 'clothing',
  //     //       name: 'Clothing',
  //     //       items: [
  //     //         { name: 'Mens Kurtas', id: 'mens_kurta' },
  //     //         { name: 'Shirt', id: 'shirt' },
  //     //         { name: 'Men Jeans', id: 'men_jeans' },
  //     //         { name: 'Sweaters', id: '#' },
  //     //         { name: 'T-Shirts', id: 't-shirt' },
  //     //         { name: 'Jackets', id: '#' },
  //     //         { name: 'Activewear', id: '#' },
              
  //     //       ],
  //     //     },
  //     //     {
  //     //       id: 'accessories',
  //     //       name: 'Accessories',
  //     //       items: [
  //     //         { name: 'Watches', id: '#' },
  //     //         { name: 'Wallets', id: '#' },
  //     //         { name: 'Bags', id: '#' },
  //     //         { name: 'Sunglasses', id: '#' },
  //     //         { name: 'Hats', id: '#' },
  //     //         { name: 'Belts', id: '#' },
  //     //       ],
  //     //     },
  //     //     {
  //     //       id: 'brands',
  //     //       name: 'Brands',
  //     //       items: [
  //     //         { name: 'Re-Arranged', id: '#' },
  //     //         { name: 'Counterfeit', id: '#' },
  //     //         { name: 'Full Nelson', id: '#' },
  //     //         { name: 'My Way', id: '#' },
  //     //       ],
  //     //     },
  //     //   ],
  //     // },
  //   ],
  //   pages: [
  //     // { name: 'Company', id: '/' },
  //     // { name: 'Stores', id: '/' },
  //   ],
  // }